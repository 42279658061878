import React, { useEffect } from 'react'
import { fstore } from '../Firebase/firebase';
import firebase from 'firebase/app';
import { UseCases } from './Constants';
import jsCookie from "js-cookie";
export default function Redirector() {

    const getUserDetails = async () => {
        let resp = null;
        try{
            resp = await makeRequestWithCreds("GET", "https://gipw.gupshup.io/is/api/user/current", true);
        } catch(err) {
            console.log("Unable to fetch user details");
        }
        return resp;
    }

    function makeRequestWithCreds(method, url, withCredentials) {
        return new Promise(function (resolve, reject) {
            let xhr = new XMLHttpRequest();
            xhr.withCredentials = withCredentials;
            xhr.open(method, url);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send();
        });
    }

    const redirectToRestaurantGip = async (data) => {
        debugger;
        let userData = await getUserDetails();
        debugger;
        if (userData) {
            try {
                data.userData= JSON.parse(userData);
                let resp = await makeRequestWithCreds("GET","https://www.gupshup.io/developer/bot/" + data.brandName + "/public?qrmetadata=" + encodeURIComponent(JSON.stringify(data)),false);
                console.log("response from bot api", resp);
                window.location.href = "https://gipw.gupshup.io/wpp/brand/" + data.brandName;
            } catch(err) {
                console.log("Error occured while sending request to bot",err);
                alert("An error occured while redirecting please try again");
            }
        } else {
            window.location.href = "https://gipw.gupshup.io/is/?redirectUrl=" + window.location.href;
        }
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        debugger;
        if (!jsCookie.get('gipuserid') && urlParams.get('gipuserid')) {
            const gipuserid = decodeURIComponent(urlParams.get('gipuserid')).replace(/"/g,"");
            if (gipuserid) {
                jsCookie.set('gipuserid', gipuserid, { expires: 5*365*24*60*60*1000, path: '/',domain: '.gupshup.io' });
            }
        }

        let path = new URL(window.location.href).pathname;
        if(process.env.NODE_ENV === 'production'){
        firebase.analytics().logEvent('qrCodeScanned', {
            scannedCode: path
        });
        }
        debugger;

        path = path.substring(1);
        if (path === "") {
            window.location.href = process.env.REACT_GIP_REDIRECT_URL;
            return;
        }
        const linkRef = fstore.collection('uuidQrCodes').doc(path);
        linkRef.get().then((doc) => {
            if (!doc.exists) {
                window.location.href = process.env.REACT_GIP_REDIRECT_URL;
                return;
            } else {
                let data = doc.data();
                if (data.useCase) {
                    if (data.useCase.toLowerCase() === UseCases.RESTAURANT) {
                        redirectToRestaurantGip(data);
                        //setTimeout(function(){ redirectToRestaurantGip(data); }, 1000);
                    }
                } else {
                    if (data.url.indexOf("http") > -1) {
                        window.location.href = data.url;
                    } else {
                        window.location.href = "https://" + data.url;
                    }
                }
                return;
            }
        });
    }, []);

    return (
        <div> </div>
    )
}
