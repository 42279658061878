import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Redirector from './components/Redirector/Redirector';

function App() {
  return (
    <Router>
      <Route path='/' component={Redirector}/>
    </Router>
  );
}

export default App;
